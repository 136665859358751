<template>
  <sd-button v-if="shipmentQuote.quotePdf"
             class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('actions.preview')"
             @click="previewQuotePdf()">
    <sd-featherIcons type="navigation" :stroke="color" size="16" />
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {previewFile} from '@/helpers/previewFile';

export default defineComponent({
  name: 'QuotePreviewButton',
  props: {
    shipmentQuote: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props) {
    const previewQuotePdf = () => {
      const quotePdfUrl = props.shipmentQuote.quotePdf?.url;
      previewFile(quotePdfUrl);
    };

    return {
      previewQuotePdf
    };
  }
});
</script>
