<template>
  <sd-button v-if="shipmentQuote.quotePdf"
             class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('actions.download')"
             @click="download()">
    <sd-featherIcons type="download" :stroke="color" size="16" />
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {downloadFile} from '@/helpers/downloadFile';

export default defineComponent({
  name: 'QuoteDownloadButton',
  props: {
    shipmentQuote: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props) {
    const download = () => {
      downloadFile(props.shipmentQuote.quotePdf?.filename, props.shipmentQuote.quotePdf?.url);
    };

    return {
      download
    };
  }
});
</script>
