<template>
  <PriceSummary>
    <div v-if="quote" class="invoice-summary-inner">
      <ul class="summary-list">
        <template v-for="fee in [quote.shipmentType === 'IOR' ? 'totalIorFees' : 'totalEorFees',
                                'systemCommissions', 'adminFees']" :key="fee">
          <li>
            <span class="summary-list-title">
              {{$t(`models.shipmentQuote.attributes.${fee}`)}}
            </span>
            <span class="summary-list-text">
              {{`${quote.currency || ''}${quote[fee] || 0}`}}
            </span>
          </li>
        </template>
      </ul>
    </div>
  </PriceSummary>
  <div v-if="quote" class="instance-quote-modal">
    <template v-for="category in filteredCategories" :xs="24" :key="category.name">
      <category-items
        :currency="quote.currency"
        :title="$t(`models.priceCategory.attributes.categoryTypes.${category.name}`)"
        :items="category.subCategories"
      />
    </template>
  </div>
  <PriceSummary>
    <div v-if="quote" class="invoice-summary-inner">
      <sdHeading class="summary-total" as="h4">
        <span class="summary-total-label">{{$t('models.shipmentQuote.attributes.grandTotal')}}: </span>
        <span class="summary-total-amount">
          {{`${quote.currency || ''}${$_.round(quote.grandTotal, 2) || 0}`}}
        </span>
      </sdHeading>
    </div>
  </PriceSummary>
</template>

<script>
import {
  defineComponent, computed
} from 'vue';
import VueTypes from 'vue-types';
import CategoryItems from '@/components/shipmentQuotes/CategoryItems';
import {PriceSummary} from '@/components/shared/styledComponents/PriceSummary';
export default defineComponent({
  name: 'QuoteBreakdown',
  components: {
    CategoryItems, PriceSummary
  },
  props: {
    quote: VueTypes.object.def(undefined)
  },
  setup(props) {
    const filteredCategories = computed(() =>
      props.quote.categories.filter((category) => category.name !== 'vat' && category.name !== 'duties'));

    return {
      filteredCategories
    };
  }
});
</script>

<style lang="scss">
.invoice-summary-inner {
  .summary-list {
    margin-bottom: 22px;
    li {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

.summary-list {
  li {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 18px;
    }
    span {
      font-weight: 500;
    }
  }
}
</style>
