<template>
  <app-modal
    :title="$t('views.chat.chatHistory')"
    type="primary"
    :visible="visible"
    :onCancel="closeModal"
  >
    <div class="sc-chat-window chat-modal">
      <single-chat-wrapper>
        <sdCards headless>
          <message-list
            :messages="messages?.data"
            :me="me"
            :onLoad="load"
            :scrollBottom="scrollBottom"
            :showEmptyMessage="true"
          />
        </sdCards>
      </single-chat-wrapper>
    </div>
  </app-modal>
</template>

<script>
import {
  defineComponent, computed, ref
} from 'vue';
import AppModal from '@/components/modals/Modal';
import MessageList from './MessageList';
import {SingleChatWrapper} from './style';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'ChatModal',
  components: {
    AppModal,
    MessageList,
    SingleChatWrapper
  },
  props: {
    visible: VueTypes.bool.def(false),
    quoteId: VueTypes.string
  },
  emits: ['closeModal'],
  setup(props, context) {
    const {getters, dispatch} = useStore();

    const messages = computed(() => getters.messages);
    const me = ref(getters.me);
    const scrollBottom = ref(false);

    const closeModal = () => context.emit('closeModal');

    const load = async ({loaded}) => {
      dispatch('getMessages', props.quoteId).then(() => {
        return loaded(messages.value.totalPages - messages.value.page);
      });
    };

    return {
      closeModal,
      open,
      close,
      load,
      scrollBottom,
      me,
      messages
    };
  }
});
</script>
