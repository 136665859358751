<template>
  <app-modal :title="$t('views.shipmentQuote.breakdown')"
             type="primary" :visible="visible" :onCancel="closeModal">
    <instant-quote-modal v-if="breakdown" :quote="breakdown" />
  </app-modal>
</template>

<script>
import {defineComponent, toRefs} from 'vue';
import VueTypes from 'vue-types';
import AppModal from '@/components/modals/Modal';
import InstantQuoteModal from './InstantQuoteModal';

export default defineComponent({
  name: 'FeesModal',
  components: {AppModal, InstantQuoteModal},
  emits: ['closeModal'],
  props: {
    quote: VueTypes.object.def({}),
    visible: VueTypes.bool.def(false)
  },
  setup(props, context) {
    const {quote} = toRefs(props);

    const closeModal = () => {
      context.emit('closeModal');
    };

    return {
      breakdown: quote,
      closeModal
    };
  }
});
</script>

<style lang="scss">
  .invoice-summary-inner {
    .summary-list {
      margin-bottom: 22px;
      li {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }

  .summary-list {
    li {
      display: flex;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 18px;
      }
      span {
        font-weight: 500;
      }
    }
  }
</style>
