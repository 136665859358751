<template>
  <pop-over
    :requiredInput="true"
    :inputFieldType="'datePicker'"
    :placeholder="'views.shipmentQuote.plannedPayment'"
    @confirm="approve($event)"
    :title="$t('views.confirm.approveQuote.title')"
    :displayedMessage="$t('views.confirm.approveQuote.message')">
    <sd-button class="btn-icon"
               type="default"
               shape="circle"
               :tooltip="$t('actions.approve')">
      <sd-feather-icons type="check" :stroke="color" size="16"/>
    </sd-button>
  </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver.vue';
import _ from 'lodash';

export default defineComponent({
  name: 'ShipmentApproveButton',
  emits: ['success'],
  components: {
    PopOver
  },
  props: {
    shipmentQuoteId: VueTypes.string,
    color: VueTypes.string.def('')
  },
  setup(props, context) {
    const {dispatch} = useStore();
    const approve = async (formData) => {
      const form = {};
      form[_.snakeCase('plannedPayment')] = formData ? formData : undefined;
      context.emit('toggleTableLoading', true);
      await dispatch('approveShipmentQuote', {
        id: props.shipmentQuoteId,
        form
      });
      context.emit('success');
    };

    return {
      approve
    };
  }
});
</script>
