<template>
  <a-popconfirm class="temp"
                @confirm="requestRevalidation(shipmentQuoteId)"
                :title="$t('views.shipmentQuote.confirmRevalidate')">
    <sd-button class="btn-icon"
               type="default"
               shape="circle"
               :tooltip="$t('actions.requestRevalidation')">
      <sd-feather-icons type="refresh-cw" :stroke="color" size="16"/>
    </sd-button>
  </a-popconfirm>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'QuoteRequestRevalidateButton',
  emits: ['success'],
  props: {
    shipmentQuoteId: VueTypes.string,
    color: VueTypes.string.def('')
  },
  setup(props, context) {
    const {dispatch} = useStore();

    const requestRevalidation = (shipmentQuoteId) => dispatch('requestQuoteRevalidation', shipmentQuoteId).then(() => context.emit('success'));

    return {
      requestRevalidation
    };
  }
});
</script>
