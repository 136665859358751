<template>
  <pop-over @confirm="reject(shipmentQuote, $event)"
            :title="$t('views.confirm.rejectQuote.title')"
            :requiredInput="true"
            :displayedMessage="$t('views.confirm.rejectQuote.message')">
    <sd-button class="btn-icon" type="default" shape="circle"
              :tooltip="$t('actions.reject')">
      <sd-feather-icons type="x-circle" :stroke="color" size="16" />
    </sd-button>
  </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver.vue';
import _ from 'lodash';

export default defineComponent({
  name: 'ShipmentRejectButton',
  emits: ['success'],
  components: {
    PopOver
  },
  props: {
    shipmentQuote: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props, context) {
    const {dispatch} = useStore();

    const reject = async (shipmentQuote, event) => {
      const form = {};
      form[_.snakeCase('rejectionReason')] = event ? event : undefined;
      context.emit('toggleTableLoading', true);
      await dispatch('rejectShipmentQuote', {
        id: shipmentQuote._jv.id,
        form
      });
      context.emit('success');
    };

    return {
      reject
    };
  }
});
</script>

