<template>
  <sd-button class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('actions.edit')"
             @click="edit()"
  >
    <sd-feather-icons type="edit" size="16"/>
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'QuoteEditButton',
  props: {
    shipmentQuoteId: VueTypes.string.required,
    shipmentId: VueTypes.string.required,
    shipmentRequestId: VueTypes.string.required
  },
  setup(props) {
    const router = useRouter();

    const edit = () => {
      router.push({
        name: 'AgentEditQuoteForm',
        params: {
          id: props.shipmentQuoteId,
          shipmentId: props.shipmentId,
          shipmentRequestId: props.shipmentRequestId
        }
      });
    };

    return {
      edit
    };
  }
});
</script>
