<template>
<div class='instant-quote-category'>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        size="small"
        :pagination="false"
        :columns="columns"
        :data-source="items || []"
        :rowKey="(row) => row.id"
      >
        <template #value="{record}">
          <a-row justify="end">
            <a-col>
            {{`${currency || ''}${record.value || 0}`}}
            </a-col>
          </a-row>
        </template>
        <template #name="{record}">
          {{recordName(record)}}
        </template>
        <template #dutiesRadioBtn="{record}">
          {{$t(`models.shipmentQuote.attributes.${record.ci ? 'ci' : 'cif'}`)}}
        </template>

        <template #footer>
          <a-row :gutter="[4,0]">
            <a-col :xs="12" class="total-text"> {{$t('layout.shipmentQuote.total')}} </a-col>
            <a-col :xs="12" class="align-right total-value">{{`${currency || ''}${totalSum || 0}`}}</a-col>
          </a-row>
        </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</div>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import VueTypes from 'vue-types';
import {useI18n} from 'vue-i18n';
import _ from 'lodash';

export default defineComponent({
  name: 'InstantQuoteDetails',
  components: {
    TableStyleWrapper, TableWrapper
  },
  props: {
    title: VueTypes.string.required,
    items: VueTypes.array,
    currency: VueTypes.string
  },
  setup(props) {
    const {t} = useI18n();
    const columns = computed(() => {
      const allCols = [{
        title: props.title,
        dataIndex: 'name',
        slots: {customRender: 'name'}

      },
      {
        title: t('models.shipmentQuote.attributes.percentage'),
        dataIndex: 'percentage',
        hidden: props.title !== t('models.priceCategory.attributes.categoryTypes.vat') &&
                props.title !== t('models.priceCategory.attributes.categoryTypes.duties')
      },
      {
        title: t('models.shipmentQuote.attributes.appliedOn'),
        dataIndex: 'ci',
        slots: {customRender: 'dutiesRadioBtn'},
        hidden: props.title !== t('models.priceCategory.attributes.categoryTypes.duties')

      },
      {
        title: t('layout.shipmentQuote.total'),
        dataIndex: 'value',
        slots: {customRender: 'value'}
      }];
      return _.filter(allCols, (col) => !col.hidden);
    });

    const totalSum = computed(() => {
      return _.round(_.sumBy(props.items, (item) => _.toNumber(item.value)), 2);
    });

    const recordName = (record) => {
      return props.title === t('models.priceCategory.attributes.categoryTypes.vat') ? t(`models.shipmentQuote.attributes.${record.name}`) : record.name;
    };

    return {
      columns,
      totalSum,
      recordName
    };
  }
});
</script>

<style lang="scss" scoped>
.instant-quote-category > div:first-child  {
  padding: 0 0 25px 0;
}
.align-right {
  text-align: right!important;
}
.ant-table-footer {
  .total-text, .total-value {
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .total-value {
    padding-left: 12px !important;
  }
}
</style>
