<template>
  <fees-modal
    :class="!visible ? 'fees-modal-div' : ''"
    :visible="visible"
    :quote="shipmentQuote"
    @close-modal="closeModal"
  />
  <sd-button class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('actions.view')"
             @click="view()"
  >
    <sd-feather-icons type="eye" size="16"/>
  </sd-button>
</template>

<script>
import {
  defineComponent, ref
} from 'vue';
import VueTypes from 'vue-types';
import {useRouter} from 'vue-router';
import FeesModal from '@/components/shipmentQuotes/FeesModal';

export default defineComponent({
  name: 'QuoteViewButton',
  props: {
    shipmentQuote: VueTypes.object.def({}),
    shipmentId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    shipmentRequestId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    isClient: VueTypes.bool,
    color: VueTypes.string.def('')
  },
  components: {
    FeesModal
  },
  setup(props) {
    const router = useRouter();
    const visible = ref(false);

    const view = () => {
      if (props.isClient && props.shipmentQuote.quoteType === 'instant') {
        openModal();
      } else {
        router.push({
          name: props.isClient ? 'ClientShipmentQuote' : 'AgentQuote',
          params: {
            quoteId: props.shipmentQuote._jv.id,
            shipmentId: props.shipmentId,
            shipmentRequestId: props.shipmentRequestId
          }
        });
      }
    };

    const openModal = () => visible.value = true;
    const closeModal = () => visible.value = false;

    return {
      view,
      visible,
      closeModal
    };
  }
});
</script>

<style lang="scss" scoped>
.fees-modal-div {
  display: inline-block !important;
}
</style>
