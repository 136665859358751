<template>
   <sd-button
      class="btn-icon"
      type="default"
      shape="circle"
      :tooltip="$t('actions.viewChatHistory')"
      @click="$emit('openChatHistory')"
    >
    <sd-feather-icons type="mail" size="16"/>
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'QuoteChatHistoryViewButton',
  emits: ['openChatHistory']
});
</script>
