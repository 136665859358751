<template>
  <chat-modal
    v-if="isBackOffice && chatModalQuoteId"
    :visible="chatModalVisible"
    :quoteId="chatModalQuoteId"
    @closeModal="closeChatModal"
  />
  <div>
    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table
          :columns="visibleColumns"
          :data-source="dataSource ? dataSource.data : []"
          :rowKey="(record) => record._jv.id"
          :pagination="pagination"
          :loading="loading || tableLoading"
          @change="handleTableChange">

          <template #expirationDate="{record}">
              {{record.expirationDate ? moment(record.expirationDate).format('DD MMM YYYY') : ''}}
          </template>

          <template #plannedPaymentDate="{record}">
              {{record.plannedPayment ? moment(record.plannedPayment).format('DD MMM YYYY') : ''}}
          </template>
          <template #currency>$</template>

          <template #agent="{record}">
            <template v-if="record.quoteType === 'agent'">
                {{record._jv.relationships.agent.name}}
              <div>
                <caption>{{record._jv.relationships.agent.country}}</caption>
                <div class="product-single-rating">
                  <a-rate allow-half :default-value="record._jv.relationships.agent.overallRating" disabled />
                  <span class="ant-rate-text">{{record._jv.relationships.agent.overallRating}}</span>
                </div>
              </div>
            </template>
          </template>

          <template #shipment="{record}">
            {{record._jv.relationships.shipment.identifier}}
          </template>

          <template #grandTotal="{record}">
            {{$_.round(record.grandTotal, 2)}}
          </template>

          <template #identifier="{record}">
            {{record.identifier}}
            <template v-if="record.additionalQuote">
             <a-tooltip class="additional-quote-tooltip" :title="$t('views.shipmentQuote.additionalQuote')">
              <sdFeatherIcons type="plus-circle" :stroke="theme['light-color']" size="12" />
             </a-tooltip>
            </template>
          </template>

          <template #status="{record}">
            <span v-if="record.quoteType === 'instant'"
                  class="status-text pink">
              {{$t(`views.shipmentQuote.instant`)}}
            </span>
            <span v-else :class="`status-text ${quoteStatusColors[$_.camelCase(record.status)]}`">
              {{$t(`models.shipmentQuote.attributes.statuses.${record.status}`)}}
            </span>
          </template>

          <template #action="{record}">
            <div class="table-actions">
              <quote-edit-button
                v-if="canEditQuote(record)"
                :shipmentQuoteId="record._jv.id"
                :shipmentRequestId="record._jv.relationships.shipment._jv.relationships.shipmentRequest.data.id"
                :shipmentId="record._jv.relationships.shipment._jv.id"
              />
              <quote-view-button v-if="!isBackOffice" :shipment-id="shipmentId" :shipment-quote="record"
                                 :shipment-request-id="shipmentRequestId" :is-client="isClient"
                                 :color="theme['info-color']"/>
              <quote-payment-button v-if="canMarkPayment(record)" @pay="payment('client', record)"
                                    :color="theme['success-color']" :icon="'arrow-down-right'"
                                    :title="'views.confirm.clientPayment.title'"
                                    :message="'views.confirm.clientPayment.message'"
                                    :tooltip="`views.shipmentQuote.${paymentTooltip('client', record)}`"
                                    :disabled="record.clientPaid"/>
              <quote-payment-button v-if="canMarkPayment(record)" @pay="payment('agent',record)"
                                    :color="theme['secondary-color']" :icon="'arrow-up-right'"
                                    :title="'views.confirm.paymentToAgent.title'"
                                    :message="'views.confirm.paymentToAgent.message'"
                                    :tooltip="`views.shipmentQuote.${paymentTooltip('agent', record)}`"
                                    :disabled="record.paidToAgent"/>
              <quote-preview-button v-if="record.quoteType === 'agent'" :shipment-quote="record"
                                  :color="theme['primary-color']"/>
              <quote-download-button v-if="record.quoteType === 'agent'" :shipment-quote="record"
                                  :color="theme['primary-color']"/>
              <quote-approve-button v-if="clientCanApproveQuote(can, record) && isClient"
                                    :shipment-quote-id="record._jv.id"
                                    @success="handleTableChange(...[pagination, filtersState, {}])"
                                    @toggleTableLoading="toggleTableLoading"
                                    :color="theme['info-color']"/>
              <quote-reject-button v-if="canRejectQuote(can, record) && isClient"
                                   :shipment-quote="record"
                                   @success="handleTableChange(...[pagination, filtersState, {}])"
                                   @toggleTableLoading="toggleTableLoading"
                                   :color="theme['danger-color']"/>
              <quote-request-revalidate-button v-if="canRequestRevalidation(record)"
                                               :shipment-quote-id="record._jv.id"
                                               @success="handleTableChange(...[pagination, filtersState, {}])"
                                               :color="theme['info-color']"/>
             <quote-chat-history-view-button v-if="isBackOffice" @openChatHistory="openChatModal(record._jv.id)" />
            </div>
          </template>
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>

<script>
import {
  computed, reactive, defineComponent, watch, ref
} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import moment from 'moment';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {shipmentQuoteStatuses} from '@/constants';
import {quoteStatusColors} from '@/helpers/quoteStatusColors';
import {
  clientCanApproveQuote, canRejectQuote, canModifyQuote
} from '@/helpers/quoteActionsConditions';
import {theme} from '@/config/theme/themeVariables';
import QuotePaymentButton from '@/components/shipmentQuotes/QuotePaymentButton';
import QuoteViewButton from '@/components/shipmentQuotes/QuoteViewButton';
import QuotePreviewButton from '@/components/shipmentQuotes/QuotePreviewButton';
import QuoteDownloadButton from '@/components/shipmentQuotes/QuoteDownloadButton';
import QuoteApproveButton from '@/components/shipmentQuotes/QuoteApproveButton';
import QuoteRejectButton from '@/components/shipmentQuotes/QuoteRejectButton';
import QuoteRequestRevalidateButton from '@/components/shipmentQuotes/QuoteRequestRevalidateButton';
import QuoteChatHistoryViewButton from '@/components/shipmentQuotes/QuoteChatHistoryViewButton';
import QuoteEditButton from './QuoteEditButton';
import ChatModal from '@/components/shared/chat/ChatModal.vue';
import {useAbility} from '@casl/vue';

export default defineComponent({
  name: 'ShipmentQuotesTable',
  components: {
    TableStyleWrapper, TableWrapper, QuoteViewButton, QuotePreviewButton, QuoteDownloadButton, QuoteApproveButton,
    QuoteRejectButton, QuoteRequestRevalidateButton, QuoteEditButton, QuotePaymentButton,
    ChatModal, QuoteChatHistoryViewButton
  },
  props: {
    searchValue: VueTypes.string.def(''),
    dateValue: VueTypes.object.def(undefined)
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const {t} = useI18n();

    const isClient = computed(() => store.state.session.userType === 'ClientUser');
    const isBackOffice = computed(() => store.state.session.userType === 'BackOfficeUser');
    const {shipmentId, shipmentRequestId} = router.currentRoute.value.params;
    const canMarkPayment = (quote) => isBackOffice.value && quote.status === shipmentQuoteStatuses.APPROVED;

    const columns = computed(() =>[
      {
        title: t('models.shipment.attributes.identifier'),
        slots: {customRender: 'shipment'},
        visible: !isClient.value
      },
      {
        title: t('models.shipmentQuote.attributes.identifier'),
        slots: {customRender: 'identifier'},
        dataIndex: 'identifier',
        visible: true
      },
      {
        title: t('models.shipmentQuote.attributes.currency'),
        slots: {customRender: 'currency'},
        // TODO: use record's currency
        // dataIndex: 'currency',
        visible: true
      },
      {
        title: t('models.shipmentQuote.attributes.grandTotal'),
        dataIndex: 'grandTotal',
        slots: {customRender: 'grandTotal'},
        sorter: true,
        visible: true
      },
      {
        title: t('models.shipmentQuote.attributes.agentCompany'),
        dataIndex: 'overallRating',
        slots: {customRender: 'agent'},
        sorter: true,
        visible: isClient.value || isBackOffice.value
      },
      {
        title: t('models.shipmentQuote.attributes.expirationDate'),
        dataIndex: 'expirationDate',
        slots: {customRender: 'expirationDate'},
        visible: true
      },
      {
        title: t('models.shipmentQuote.attributes.status'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
        visible: true,
        filters: _.map(_.reject(shipmentQuoteStatuses, (status) => {
          return status === shipmentQuoteStatuses.WAITING_AGENT_RESPONSE ||
            (isClient.value && status === shipmentQuoteStatuses.WAITING_PRIMARY_USER_APPROVAL &&
            status !== shipmentQuoteStatuses.WAITING_AGENT_RESPONSE);
        }), (value) => {
          return {text: t(`models.shipmentQuote.attributes.statuses.${value}`), value};
        })
      },
      {
        title: t('models.shipmentQuote.attributes.plannedPayment'),
        dataIndex: 'plannedPayment',
        slots: {customRender: 'plannedPaymentDate'},
        sorter: true,
        visible: isClient.value || isBackOffice.value
      },
      {
        title: '',
        key: 'action',
        slots: {customRender: 'action'},
        visible: true
      }
    ]);

    const visibleColumns = computed(() => columns.value.filter((column) => column.visible));

    const getShipmentQuotes = (params) => store.dispatch('getShipmentQuotes', {...params, shipment_id: shipmentId});
    const tableLoading = ref(false);
    const toggleTableLoading = (value) => {
      tableLoading.value = value;
    };

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize
    } = usePagination(getShipmentQuotes, {
      formatResult: () => store.getters.shipmentQuotes,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const pagination = computed(() => ({
      total: store.getters.shipmentQuotes?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const filtersChanged = ref(false);
    const filtersState = reactive({
      status: undefined, search: undefined, expirationDate: undefined
    });

    const sortState = reactive({
      col: undefined, direction: undefined
    });

    watch(() => [props.searchValue, props.dateValue], () => {
      filtersChanged.value = true;
      filtersState.search = props.searchValue;
      filtersState.expirationDate = props.dateValue ? props.dateValue.format('YYYY-MM-DD') : '';
      if (props.searchValue.length === 0 || props.searchValue.length >= 3) {
        setTimeout(() => handleTableChange(pagination.value, filtersState, {}), 100);
      }
    });

    const handleTableChange = (pag, filters, sorter) => {
      const filterParams = {};
      _.forEach(filtersState, (field, key) => {
        filtersState[key] = filters[key] || field;
        filterParams[_.snakeCase(key)] = filtersState[key];
      });

      if (!_.isEmpty(sorter)) {
        sortState.col = _.snakeCase(sorter.field);
        sortState.direction = sorter.order === 'ascend' ? 'asc' : 'desc';
      }

      run({
        per_page: pag.pageSize,
        page: filtersChanged.value ? 1 : pag?.current,
        order_by: sortState.col,
        order_dir: sortState.direction,
        ...filterParams
      });
      filtersChanged.value = false;
      tableLoading.value = false;
    };

    const paymentTooltip = (user, quote) => {
      if (user === 'client') {
        return quote.clientPaid ? 'clientPaidDone' : 'clientPaid';
      } else {
        return quote.paidToAgent ? 'paidToAgentDone' : 'paidToAgent';
      }
    };

    const payment = (user, quote) => {
      const form = {};
      user === 'client' ? form[_.snakeCase('clientPaid')] = true : form[_.snakeCase('paidToAgent')] = true;
      store.dispatch('markPaymentShipmentQuote', {
        id: quote._jv.id,
        form
      }).then(() => handleTableChange(...[pagination, filtersState, {}]));
    };
    const {can} = useAbility();
    const canRequestRevalidation = (quote) => can('requestRevalidation', quote);
    const canEditQuote = (quote) => canModifyQuote(quote, quote. _jv.relationships.shipment);

    const chatModalVisible = ref(false);
    const chatModalQuoteId = ref();
    const openChatModal = (quoteId) => {
      store.dispatch('clearChat');
      store.dispatch('subscribeToMessagesChannel', quoteId);
      chatModalQuoteId.value = quoteId;
      chatModalVisible.value = true;
    };
    const closeChatModal = () => {
      store.dispatch('unsubscribeToMessagesChannel', chatModalQuoteId.value);
      chatModalVisible.value = false;
      chatModalQuoteId.value = undefined;
    };

    return {
      theme,
      isClient,
      isBackOffice,
      canMarkPayment,
      shipmentId,
      shipmentRequestId,
      dataSource,
      pagination,
      filtersState,
      loading,
      tableLoading,
      toggleTableLoading,
      visibleColumns,
      moment,
      shipmentQuoteStatuses,
      quoteStatusColors,
      handleTableChange,
      canRequestRevalidation,
      payment,
      paymentTooltip,
      canEditQuote,
      clientCanApproveQuote,
      canRejectQuote,
      can,
      chatModalVisible,
      chatModalQuoteId,
      openChatModal,
      closeChatModal
    };
  }
});
</script>

<style lang="scss" scoped>
.additional-quote-tooltip svg.feather.feather-plus-circle {
  vertical-align: middle;
};
</style>
