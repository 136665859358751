<template>
  <div>
    <app-header
      :routes="routes"
      :buttons="buttons"
      @header-action="addAdditionalQuote()"
    />
    <Main>
      <a-row :gutter="10">
        <a-col :xs="24" class="table-search">
          <a-row :gutter="[10,0]" type="flex" justify="end">
            <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="3">
              <a-input-search v-model:value="searchValue" :placeholder="$t('actions.search')" />
            </a-col>
            <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="3">
              <a-date-picker v-model:value="dateValue" :inputReadOnly="true" class="date-search"
                             :placeholder="$t('models.shipmentQuote.attributes.expirationDate')" />
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="24">
          <sdCards headless>
            <shipments-quotes-table
              :key="`${routeName}_${shipmentId}`"
              :searchValue="searchValue"
              :dateValue ="dateValue"
            />
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  defineComponent, computed, ref
} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import ShipmentsQuotesTable from '@/components/shipmentQuotes/Table';
import {canModifyQuote} from '@/helpers/quoteActionsConditions';
import {shipmentQuoteStatuses} from '@/constants';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'ClientShipmentRequests',
  components: {
    Main,
    ShipmentsQuotesTable
  },
  setup() {
    const route = useRoute();
    const {
      getters, dispatch, state
    } = useStore();
    const {shipmentRequestId, shipmentId} = route.params;
    const routeName = computed(() => route.name);

    const isClient = computed(() => state.session.userType === 'ClientUser');
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');

    const getShipment = () => {
      if (route.name === 'AgentQuotes' || isBackOffice.value) return;
      dispatch('getShipment', {
        shipmentRequestId,
        shipmentId,
        params: {fields: ['agentMainQuote', 'pendingCancellation']}
      });
    };

    const shipment = computed(() => getters.shipment);

    const routes = computed(() => {
      if (isBackOffice.value) {
        return [{
          name: 'BackOfficeShipments',
          breadcrumbName: 'views.shipmentRequest.shipments'
        },
        {
          name: 'BackOfficeShipmentDetails',
          params: {shipmentRequestId: shipmentRequestId, shipmentId: shipmentId},
          breadcrumbName: 'views.shipmentRequest.shipment'
        },
        {
          name: 'BackOfficeShipmentQuotes',
          params: {shipmentId: shipmentId},
          breadcrumbName: 'views.shipmentQuote.quotes'
        }];
      } else if (route.name === 'AgentQuotes') {
        return [{
          name: route.name,
          breadcrumbName: 'views.shipmentQuote.index'
        }];
      } else {
        const breadcrumbs = [{
          name: isClient.value ? 'ClientShipmentRequests' : 'AgentShipments',
          breadcrumbName: isClient.value ? 'layout.shipmentRequest.index' : 'layout.shipmentRequest.shipments'
        },
        {
          name: `${isClient.value ? 'Client' : 'Agent'}ShipmentQuotes`,
          params: {shipmentRequestId: shipmentRequestId, shipmentId: shipmentId},
          breadcrumbName: 'views.shipmentQuote.quotes'
        }];

        if (isClient.value) {
          breadcrumbs.splice(1, 0, {
            name: `${isClient.value ? 'Client' : 'Agent'}ShipmentRequestDetails`,
            params: {shipmentRequestId: shipmentRequestId},
            breadcrumbName: 'views.shipmentRequest.details'
          });
        }

        return breadcrumbs;
      }
    });

    const searchValue = ref('');
    const dateValue = ref();

    const {t} = useI18n();
    const buttons = computed(() => {
      shipment.value;
      const quoteStatuses = [shipmentQuoteStatuses.APPROVED,
        shipmentQuoteStatuses.REVALIDATION_REQUESTED];
      const canUpdateQuote = _.includes(quoteStatuses, shipment.value.agentMainQuote?.status) &&
        canModifyQuote(shipment.value.agentMainQuote, shipment.value, true);
      if (route.name === 'AgentShipmentQuotes' && canUpdateQuote) {
        return [{
          action: 'addAdditionalQuote',
          icon: 'plus',
          label: 'views.shipmentQuote.addAdditionalQuote',
          disabled: shipment.value.pendingCancellation,
          tooltip: shipment.value.pendingCancellation ? t('messages.help.shipmentPendingCancellation') : '',
          tooltipPlacement: 'left'
        }];
      } else return [];
    });

    const router = useRouter();
    const addAdditionalQuote = () => {
      router.push({
        name: 'AgentAdditionalQuoteForm',
        params: {
          shipmentId, shipmentRequestId
        }
      });
    };

    getShipment();

    return {
      routes,
      shipmentId,
      searchValue,
      dateValue,
      buttons,
      routeName,
      addAdditionalQuote
    };
  }
});
</script>
