<template>
  <pop-over
    :title="$t(title)"
    :displayedMessage="$t(message)"
    :canInput="false"
    @confirm="confirmPayment()"
  >
    <sd-button class="btn-icon"
              type="default"
              shape="circle"
              :disabled="disabled"
              :tooltip="$t(tooltip)"
    >
      <sd-feather-icons :type="icon" size="16" :stroke="color"/>
    </sd-button>
  </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import PopOver from '@/components/modals/PopOver';
export default defineComponent({
  name: 'QuotePaymentButton',
  components: {
    PopOver
  },
  emits: ['pay'],
  props: {
    disabled: VueTypes.bool.def(false),
    icon: VueTypes.string.def(''),
    color: VueTypes.string.def(''),
    tooltip: VueTypes.string.def(''),
    title: VueTypes.string.def(''),
    message: VueTypes.string.def('')
  },
  setup(props, context) {
    const confirmPayment = () => {
      context.emit('pay');
    };
    return {
      confirmPayment
    };
  }
});
</script>

